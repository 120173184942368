<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">Payment</h3>
      <ul>
        <li>
          <a href="index.html">Financial Status</a>
        </li>
        <li>All Financial Statues</li>
      </ul>
    </div> -->
    <div class="card height-auto v-application v-application--is-ltr">
      <div class="card-body">
        <div class="heading-layout1">
          <div class="item-title"></div>
        </div>

        <!-- -----------------------------------second data table --------------------------->
        <v-data-table
          v-can="'list-financial-status'"
          :options.sync="options"
          :server-items-length="this.totalApplicants"
          :headers="headers"
          :items="applicants"
          :loading="loading"
          loading-text="Loading... Please wait"
          class="elevation-1 level1"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Student Financial Status") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    v-model="search"
                  />
                </label>
                <!-- filter -->
                <span :title="$t('Filter')">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i v-on="on" class="fas fa-filter main-color fa-lg"></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.grades"
                          :label="$t('Grade')"
                          :items="grades"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.plans"
                          :label="$t('Fee plan')"
                          :items="plans"
                          @change="hideLabel = true"
                          item-text="title"
                          item-value="id"
                          multiple
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.status"
                          :label="$t('Status')"
                          :items="status"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          solo
                        ></v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>
              <v-col md="4" sm="12">
                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/financial_status"
                  v-if="activitylogPermission"
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- <template v-slot:item.notes="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.notes">
                  {{ item.notes.slice(0, 15) }}
                </p>
              </template>
              <span>{{ item.notes }}</span>
            </v-tooltip>
          </template> -->
          <template v-slot:item.student_full_name="{ item }">
            <StudentHoverMenu
              :studentName="item.student_full_name"
              :studentId="item.student_id"
              :userId="item.user_id"
              :archive="item.archive"
              v-if="item.student_id"
            ></StudentHoverMenu>
            <v-tooltip bottom max-width="300px" v-else>
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.student_full_name">
                  {{ item.student_full_name.slice(0, 20) }}
                </p>
              </template>
              <span>{{ item.student_full_name }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.action="{ item }">
            <!-- <a
              v-if="changefinanicalStatus"
              @click="openModal(item)"
              class="mr-1"
              small
            >
              <i class="fas fa-money-check-alt" style="color:gray;"></i>
            </a> -->

            <!-- <a
              v-if="
                item.global_status == 'pending_school_fees' &&
                  acceptApplicantIcon
              "
              @click="AcceptApplicant(item)"
              :class="{ disabled: acceptApplicantDisabled }"
              class="mr-1"
              small
            >
              <i class="fas fa-user-check" style="color:gray;"></i>
            </a> -->

            <a
              class="mr-1 invoice"
              @click="openCollectCashDialog(item.id)"
              v-if="create_invoice && item.global_status == 'student'"
              :title="$t('Create Invoice')"
            >
              <i class="fas fa-file-invoice"></i>
            </a>
          </template>
        </v-data-table>
      </div>
    </div>

    <div class="text-center pt-2">
      <v-pagination
        v-if="length > 0 && totalApplicants > 0"
        v-model="page"
        :length="length"
        :circle="circle"
        :page="page"
        :total-visible="totalVisible"
        :disabled="disablePagination"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      ></v-pagination>
    </div>
    <!-- ==============================this is dialog popup==================================== -->
    <v-dialog
      v-model="dialogfinanial"
      v-if="dialogfinanial"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("Payment") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Code") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                {{ applicant.application_number }}
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Name") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                {{ applicant.student_full_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Grade") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                {{ applicant.student_grade_level }}
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                <span class="key"> {{ $t("Nationality") }}: </span>
              </v-col>
              <v-col cols="12" lg="3" md="6" xs="12">
                {{ applicant.student_nationality }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" sm="9">
                <label>{{ $t("Notes") }}</label>
                <v-textarea
                  class="mx-2"
                  rows="1"
                  :auto-grow="true"
                  v-model.trim="paymentStatus.notes"
                  solo
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="my-2 text-center">
              <v-col cols="3" sm="3">
                <v-btn
                  small
                  class="approvebuttonvutify"
                  @click="dialogConfirm = true"
                  :disabled="paidStatus || !loaded"
                  >{{ $t("Pay Admission Exam") }}</v-btn
                >
              </v-col>
              <v-col cols="3" sm="3">
                <v-btn
                  small
                  class="approvebuttonvutify"
                  :disabled="!paidStatus || !loaded"
                  @click="dialogConfirmrefund = true"
                  >{{ $t("Refund Admission Exam") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="modal-btn-save" @click="close">{{
              $t("Close")
            }}</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>

    <!-- confirmation pay message -->
    <v-dialog
      v-model="dialogConfirm"
      v-if="dialogConfirm"
      persistent
      max-width="300"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to pay for this applicant exam?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn-cancel"
            @click="dialogConfirm = false"
            id="noBtn"
          >
            {{ $t("No") }}
          </v-btn>
          <v-btn
            class="modal-btn-save"
            @click="confirmPay"
            :loading="disabled"
            :disabled="disabled"
            id="yesBtn"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation refund message -->
    <v-dialog
      v-model="dialogConfirmrefund"
      v-if="dialogConfirmrefund"
      persistent
      max-width="300"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to refund your fees?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn-cancel"
            @click="dialogConfirmrefund = false"
            id="noBtn2"
          >
            {{ $t("No") }}
          </v-btn>
          <v-btn
            class="modal-btn-save"
            @click="confirmRefund"
            :loading="disabled"
            :disabled="disabled"
            id="yesBtn2"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- confirmation refund message -->
    <v-dialog
      v-model="dialogConfirmrefund"
      v-if="dialogConfirmrefund"
      persistent
      max-width="300"
      class="dropdown-item dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Confirmation Message")
        }}</v-card-title>

        <v-card-text>{{
          $t("Are you sure you want to refund your fees?")
        }}</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="modal-btn-cancel"
            @click="dialogConfirmrefund = false"
            id="noBtn3"
          >
            {{ $t("No") }}
          </v-btn>
          <v-btn
            class="modal-btn-save"
            @click="confirmRefund"
            :loading="disabled"
            :disabled="disabled"
            id="yesBtn3"
          >
            {{ $t("Yes") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- collect cash component -->
    <CollectCashComponent
      :collectCashDialog="collectCashDialog"
      :applicant_id="applicant_id"
      v-on:childToParent="closeCollectCashDialog"
    >
    </CollectCashComponent>
  </div>
</template>
<script>
import axios from "axios";
import ACL from "../../acl";
import CollectCashComponent from "../../components/modals/CollectCashComponent";
import StudentHoverMenu from "@/components/Students/StudentHoverMenu";

export default {
  components: { CollectCashComponent, StudentHoverMenu },
  data() {
    return {
      filterMenu: false,
      activitylogPermission: ACL.checkPermission("activitylog"),

      //pagination options
      disablePagination: false,
      changefinanicalStatus: ACL.checkPermission(
        "change-financial-status-payment-status"
      ),
      acceptApplicantIcon: ACL.checkPermission("accept-applicant-as-student"),
      acceptApplicantDisabled: false,
      page: 0,
      circle: true,
      length: 0,
      totalVisible: 0,
      // end pagination
      dialogConfirm: false,
      dialogConfirmrefund: false,
      snack: false,
      snackColor: "",
      snackText: "",
      snackTime: 7000,
      applicantLoaded: false,
      loaded: true,
      dialog: false,
      dialogfinanial: false,
      applicant: [],
      paidStatus: false,
      paymentStatus: {
        exam_payment_status: false,
        notes: "",
      },
      headers: [
        {
          text: this.$i18n.t("Code"),
          align: "left",
          value: "application_number",
        },
        { text: this.$i18n.t("Name"), value: "student_full_name" },
        { text: this.$i18n.t("Grade"), value: "student_grade_level" },
        { text: this.$i18n.t("Fee Plan"), value: "price_plan" },
        { text: this.$i18n.t("Contact Person"), value: "contact_person_name" },
        { text: this.$i18n.t("Mobile"), value: "contact_person_mobile" },
        { text: this.$i18n.t("Status"), value: "exam_payment_status" },
        // { text: "Notes", value: "notes", sortable: false, width: "15%" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          width: "10%",
        },
      ],
      financialStatus: [],
      applicants: [],
      loading: true,
      options: {
        itemsPerPage: 15,
      },
      totalApplicants: 0,
      search: "",
      // action menus
      actionMenus: [{ title: this.$i18n.t("ExamResult"), icon: "edit" }],
      disabled: false,

      collectCashDialog: false,
      applicant_id: "",

      create_invoice: ACL.checkPermission("create_invoice"),
      grades: [],
      plans: [],
      status: [
        this.$i18n.t("Paid"),
        this.$i18n.t("Not paid"),
        this.$i18n.t("Exception"),
      ],
      filter: {
        grades: [],
        plans: [],
        status: "",
      },
    };
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.getDataFromApi(this.$route.query.page);
        }
      },
    },
    options: {
      handler() {
        if (
          this.options.sortBy.length > 0 &&
          this.options.sortDesc.length > 0
        ) {
          this.getDataFromApi(this.page, this.search);
        }
      },
      deep: true,
    },
    search: {
      handler() {
        this.page = 1;
        this.getDataFromApi(1, this.search);
      },
      deep: true,
    },
    page: function () {
      this.$router.push(
        {
          path: "/financialstatus?page=" + this.page,
        },
        () => {}
      );
    },
    filter: {
      handler() {
        this.page = 1;
        this.getDataFromApi(this.page);
      },
      deep: true,
    },
  },
  methods: {
    getgrades() {
      axios
        .get(this.getApiUrl + "/homework/getGrade", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },
    getPlans() {
      axios
        .get(this.getApiUrl + "/getfeesPlans", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          if (response.data.status.error == false) {
            this.plans = response.data.data;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },

    closeCollectCashDialog() {
      this.collectCashDialog = false;
    },
    openCollectCashDialog(applicantId) {
      this.applicant_id = applicantId;
      this.collectCashDialog = true;
    },
    AcceptApplicant(item) {
      this.acceptApplicantDisabled = true;
      axios
        .post(
          this.getApiUrl + "/applicant/acceptApplicantAsStudent/" + item.id,
          this.paymentStatus,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.snack = true;
            this.snackColor = "green";
            this.snackText = response.data.status.message;
            item.global_status = "pending_complete_profile";
          } else {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
          }
          this.acceptApplicantDisabled = false;
        })
        .catch((err) => {
          alert(err);
        });
    },
    confirmPay() {
      this.acceptPayment(this.applicant.id, true);
    },
    confirmRefund() {
      this.acceptPayment(this.applicant.id, false);
    },
    close() {
      this.paymentStatus.notes = "";
      // console.log(this.paymentStatus.notes);
      this.dialogfinanial = false;
    },
    onChildClick(applicantLoaded, dialog) {
      this.applicantLoaded = applicantLoaded;
      this.dialog = dialog;
    },
    showDialog(item) {
      this.dialog = true;
      this.applicant = item;
      this.applicantLoaded = true;
      // document.getElementById("preloader-custom").classList.add("hidden");
    },
    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
      }
    },
    openModal(item) {
      this.dialogfinanial = true;
      this.applicant = item;
      if (item.exam_payment_status == "Not Paid") {
        this.paidStatus = false;
      } else if (item.exam_payment_status == "Paid") {
        this.paidStatus = true;
      }
    },
    acceptPayment(id, paid) {
      this.disabled = true;
      this.loaded = false;

      this.paymentStatus.exam_payment_status = paid;
      //   if (!paid) {
      //     document.getElementById("preloader-custom2").classList.remove("hidden");
      //   } else {
      //     document.getElementById("preloader-custom").classList.remove("hidden");
      //   }

      // send payment object to api
      axios
        .post(
          this.getApiUrl +
            "/applicant/changePaymentStatus/" +
            this.applicant.id,
          this.paymentStatus,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.loaded = true;
          let applicant = response.data.data;
          this.applicants.find(
            (object) => object.id == applicant.id
          ).exam_payment_status = applicant.exam_payment_status;

          this.applicants.find((object) => object.id == applicant.id).notes =
            applicant.notes;

          this.dialogfinanial = false;

          this.paymentStatus.exam_payment_status = false;
          this.paymentStatus.notes = "";
          this.snack = true;
          this.snackColor = "success";
          let status = "";
          if (applicant.exam_payment_status == "Paid") {
            status = "paid";
          } else {
            status = "refunded";
          }
          this.snackText = "You have " + status + " for the admission exam";
          // this.modal = false;
          if (!paid) {
            this.dialogConfirmrefund = false;
          } else {
            this.dialogConfirm = false;
          }
          this.disabled = false;
          this.applicants = [];
          this.$nextTick(function () {
            // DOM is now updated
            // `this` is bound to the current instance
            // this.$forceUpdate();
            this.getDataFromApi(this.page, this.search);
          });
        })
        .catch((err) => {
          alert(err);
        });
    },
    getDataFromApi(page, search) {
      this.disablePagination = true;
      let sort_type = "";
      let column = "";
      if (this.options.sortBy.length > 0) {
        column = this.options.sortBy[0];
        sort_type = this.options.sortDesc[0] ? "DESC" : "ASC";
      }
      if (search == "") {
        //"/applicant/applicants?approved=yes&sort_type=" +
        axios
          .get(
            this.getApiUrl +
              "/students/getStudentsFinancialStatus?sort_type=" +
              sort_type +
              "&column=" +
              column +
              "&" +
              this.type +
              "=yes&page=" +
              page,
            {
              params: {
                filter: this.filter,
              },
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.disablePagination = false;
            this.loading = false;
            this.applicants = response.data.data.data;
            this.totalApplicants = response.data.data.total;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          });
      } else {
        axios
          .get(
            this.getApiUrl +
              "/students/getStudentsFinancialStatus?&sort_type=" +
              sort_type +
              "&column=" +
              column +
              "&" +
              this.type +
              "=yes&page=" +
              page +
              "&search=" +
              this.search,
            {
              params: {
                filter: this.filter,
              },
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            this.disablePagination = false;
            this.applicants = response.data.data.data;
            this.totalApplicants = response.data.data.total;
            this.page = response.data.data.current_page;
            this.length = response.data.data.last_page;
            if (this.length > 5) this.totalVisible = 5;
          });
      }
    },
  },
  mounted() {
    this.getgrades();
    this.getPlans();
    this.page = this.$route.query.page;

    if (!this.changefinanicalStatus && !this.acceptApplicantIcon) {
      this.headers = this.headers.filter(function (row) {
        return row.value != "action";
      });
    }
    this.getDataFromApi(this.page);
    // axios
    //   .get(
    //     this.getApiUrl +
    //       "/students/getStudentsFinancialStatus?page=" +
    //       this.page,
    //     {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.token,
    //         //the token is a variable which holds the token
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     this.loading = false;
    //     if (response.data.status.error == false) {
    //       this.applicants = response.data.data.data;
    //       this.totalApplicants = response.data.data.total;
    //       this.page = response.data.data.current_page;
    //       this.length = response.data.data.last_page;
    //       if (this.length > 5) this.totalVisible = 5;
    //     }
    //   })
    //   .catch((err) => {
    //     alert(err);
    //   });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// should apply on all views
#wrapper {
  transition: all 0.4s ease 0s;
}
#wrapper.active {
  // padding-left: 200px;
}

/* start new interface */

// start search input
.search {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  margin: 0px 8px 7px 0px;
  padding: 7px 9px 0px 9px;
  border: 2px solid #bbbbbb;
  border-radius: 25px;
  transition: all 200ms ease;
  cursor: text;
  padding: 3px 5px;

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 15px;
    right: -5px;
    top: 21px;
    background: #bbbbbb;
    border-radius: 3px;
    transform: rotate(-45deg);
    transition: all 200ms ease;
  }

  &.active,
  &:hover {
    width: 200px;
    margin-right: 0px;

    &:after {
      height: 0px;
    }
  }

  input {
    width: 100%;
    border: none;
    box-sizing: border-box;
    font-family: Helvetica;
    font-size: 15px;
    color: inherit;
    background: transparent;
    outline-width: 0px;
  }
}

// end search input

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.invoice {
  color: gray;
  font-size: 13px;
}
/* end new interface */
</style>
