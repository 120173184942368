<template>
  <div>
    <v-dialog v-model="collectCashDialog" persistent width="500">
      <v-card style="height: auto">
        <v-card-text>
          <v-form ref="form" v-model="valid" class="mt-5">
            <h3 class="modal-header">{{ $t("Create Invoice") }}</h3>
            <p class="error--text text-center">{{ errorMsg }}</p>
            <v-col cols="12" sm="12">
              <label>{{ $t("Name") }}</label>
              <v-autocomplete
                :items="allFeesItems"
                v-model="selectedItems"
                item-text="item_with_price"
                item-value="id"
                chips
                solo
                multiple
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <label>{{ $t("Extra Items") }} </label>
              <v-autocomplete
                :items="allExtraItems"
                v-model="extraSelectedItems"
                item-text="item_with_price"
                item-value="id"
                chips
                solo
                multiple
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12">
              <div>
                <p v-if="errorForm" class="red--text">
                  {{ $t("One Or More field is required To Create Invoice") }}
                </p>
              </div>
            </v-col>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="Cancel"
            >{{ $t("Cancel") }}
          </v-btn>
          <v-btn
            class="modal-btn-save"
            :loading="loading"
            :disabled="loading"
            @click="save"
          >
            {{ $t("Continue") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  props: [
    "collectCashDialog",
    "applicant_id",
    "selected_Items",
    "extraSelected_Items",
    "invoice_id",
    "tableData",
  ],
  name: "CollectCashComponent",
  mixins: [validationMixin],
  data() {
    return {
      allFeesItems: [],
      selectedItems: [],
      allExtraItems: [],
      extraSelectedItems: [],
      valid: true,
      errorMsg: "",
      errorForm: false,
      loading: false,
    };
  },
  watch: {
    collectCashDialog: {
      handler() {
        // this.autoFocusDialog();
        if (this.collectCashDialog == true) {
          this.errorMsg = "";
          this.getPaymentItems();
          this.getExtraItems();
        }
        this.resetFormValidation();
      },
    },
    extraSelectedItems: {
      handler() {
        // this.extraSelectedItems();
        if (this.extraSelectedItems.length > 0) {
          this.errorForm = false;
        }
      },
    },
    selectedItems: {
      handler() {
        // this.extraSelectedItems();
        if (this.selectedItems.length > 0) {
          this.errorForm = false;
        }
      },
    },
  },
  methods: {
    getPaymentItems() {
      this.allFeesItems = [];
      this.selectedItems = [];
      let invoiceID = "";

      if (this.invoice_id) {
        invoiceID = "/" + this.invoice_id;
      }
      this.loading = true;
      axios
        .get(
          this.getApiUrl +
            "/applicant/getApplicantPaymentPlansItems/" +
            this.applicant_id +
            invoiceID,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.allFeesItems = response.data.data.items;

            if (!this.selected_Items) {
              this.allFeesItems.forEach((item) => {
                if (item.selected == true) {
                  this.selectedItems.push(item.id);
                }
              });
            } else {
              if (this.selected_Items.length > 0) {
                this.selectedItems = this.selected_Items;
              }
            }
          } else {
            this.errorMsg = "There is no unpaid items for this student";
          }
          this.loading = false;
        });
    },

    getExtraItems() {
      axios
        .get(this.getApiUrl + "/items/getItemsForInvoices", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.allExtraItems = response.data.data;

          if (!this.extraSelected_Items) {
            this.allExtraItems.forEach((item) => {
              if (item.selected == true) {
                this.extraSelectedItems.push(item.id);
              }
            });
          } else if (this.extraSelected_Items.length > 0) {
            this.extraSelectedItems = this.extraSelected_Items;
          }
        });
    },

    Cancel() {
      this.selectedItems = this.selected_Items;
      this.extraSelectedItems = this.extraSelected_Items;

      this.$emit(
        "childToParent",
        false,
        this.selectedItems,
        this.extraSelectedItems
      );
    },
    save() {
      if (
        this.selectedItems.length <= 0 &&
        this.extraSelectedItems.length <= 0
      ) {
        this.errorForm = true;
      } else {
        // eslint-disable-next-line no-empty
        if (this.valid) {
          // this.$store.commit("setInvoiceSelectedItems", this.selectedItems);
          if (!this.selected_Items) {
            this.loading = true;
            axios
              .post(
                this.getApiUrl +
                  "/invoices/storeDraftInvoice/" +
                  this.applicant_id,
                {
                  feeplans_items_ids: this.selectedItems,
                  items_ids: this.extraSelectedItems,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.token,
                  },
                }
              )
              .then((response) => {
                this.loading = false;
                const invoiceId = response.data.data.id;
                this.$router.push("/invoices/" + invoiceId);
                this.Cancel();
              });
          } else {
            let invoiceId = this.invoice_id;

            // feesItems
            let selected = this.selectedItems;

            // extra selectedItems
            let extraSelectedItems = this.extraSelectedItems;

            // all datatable items
            let dataTable = this.tableData;

            // for feesItems
            let defaultObj = {
              invoice_id: "",
              item_id: "",
              type: "item",
              due_amount: "",
              due_date: "",
              taxable: "",
              discount: 0,
              amount: 0,
              notes: null,
              item: "",
              relation_type: "feeplan",
            };

            // when user add data to table feesItems
            selected.forEach((id) => {
              // eslint-disable-next-line no-empty
              if (dataTable.find((row) => row.item_id == id)) {
              } else {
                this.allFeesItems.find((item) => {
                  if (item.id == id) {
                    defaultObj.invoice_id = invoiceId;
                    defaultObj.item_id = item.id;
                    defaultObj.due_amount = item.price;
                    defaultObj.amount = item.price;
                    defaultObj.due_date = item.due_date;
                    defaultObj.item = item.item;
                    defaultObj.taxable = item.taxable;

                    dataTable.unshift(defaultObj);
                    // tableitemIds.push(item.id);
                  }

                  defaultObj = {
                    invoice_id: "",
                    item_id: "",
                    type: "item",
                    due_amount: "",
                    due_date: "",
                    discount: 0,
                    amount: 0,
                    notes: null,
                    item: "",
                    relation_type: "feeplan",
                  };
                });
              }
            });

            // when user delete data from selected feesItems
            dataTable.forEach((row) => {
              if (
                selected.includes(row.item_id) == false &&
                row.type != "tax" &&
                row.relation_type == "feeplan"
              ) {
                dataTable = dataTable.filter((item) => {
                  return item.item_id != row.item_id;
                });
              }
            });

            this.tableData = dataTable;
            this.selected_Items = [];

            // for extra items
            let extraDefaultObj = {
              invoice_id: "",
              item_id: "",
              type: "item",
              due_amount: "",
              due_date: "",
              taxable: "",
              discount: 0,
              amount: 0,
              notes: null,
              item: "",
              relation_type: "item",
            };

            // when user add data to table extraItems
            extraSelectedItems.forEach((id) => {
              // eslint-disable-next-line no-empty
              if (dataTable.find((row) => row.item_id == id)) {
              } else {
                this.allExtraItems.find((item) => {
                  if (item.id == id) {
                    extraDefaultObj.invoice_id = invoiceId;
                    extraDefaultObj.item_id = item.id;
                    extraDefaultObj.due_amount = item.price;
                    extraDefaultObj.amount = item.price;
                    extraDefaultObj.due_date = item.due_date;
                    extraDefaultObj.item = item.item;
                    extraDefaultObj.taxable = item.taxable;

                    dataTable.unshift(extraDefaultObj);
                    // tableitemIds.push(item.id);
                  }

                  extraDefaultObj = {
                    invoice_id: "",
                    item_id: "",
                    type: "item",
                    due_amount: "",
                    due_date: "",
                    discount: 0,
                    amount: 0,
                    notes: null,
                    item: "",
                    relation_type: "item",
                  };
                });
              }
            });

            // console.log(dataTable);

            // when user delete data from selected extraItems
            dataTable.forEach((row) => {
              if (
                extraSelectedItems.includes(row.item_id) == false &&
                row.type != "tax" &&
                row.relation_type == "item"
              ) {
                dataTable = dataTable.filter((item) => {
                  return item.item_id != row.item_id;
                });
              }
            });

            this.tableData = dataTable;

            this.$emit(
              "childToParent",
              this.tableData,
              this.selectedItems,
              this.extraSelectedItems
            );
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },

    resetFormValidation() {
      // reset form validations
      if (this.$refs.form) {
        this.errorForm = false;
        this.$refs.form.reset();
      }
    },

    // validation
    multiValuesRequireRule(items) {
      if (items.length < 1) {
        return this.$i18n.t("This field is required");
      } else {
        return true;
      }
    },
  },
  mounted() {
    // this.getPaymentItems();
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  margin: 0 !important;
}
</style>
